import React from 'react';
import styles from './ProgressIntro.module.css';
import Button from '../../components/Button/Button';

export default function ProgressIntro() {
    return(
        <div className= {styles.container + ' container-margin'}>
            <p className={styles.title}>
                Ya formas parte del programa Súbete a la Nube con Google Cloud
            </p>
            <p>
                Plataforma innovadora de entrenamiento en la que los usuarios pueden acceder a certificados y cursos con la opción de seguir
                diferentes caminos de carrera que le ayudarán a construir un perfil profesional en Cloud Computing. <br />
            </p>
            <div>
                <a className={styles.link} href="https://storage.googleapis.com/inroads_prod_cloud_public/noticias/Kit%20de%20bienvenida.pdf" target="_blank" rel="noreferrer">
                    <Button backGroundColor={'#2282f4'}>Kit Súbete a la Nube</Button>
                </a>
            </div>
        </div>
    );
};
